export const setCoordinatorsOrder = (specialtyCoordinators, formattedDoctors) => {
    let newArray = []
    let arrayForOrder = []
    specialtyCoordinators.forEach((coordinator, indexCoordinator) => {
      formattedDoctors.forEach((item, index) => {
        if (item.id_cms === coordinator.json.children[0].nodeId) {
          if (newArray.length > 0) {
            newArray = [item, ...newArray]
            arrayForOrder = [item, ...arrayForOrder]
          } else {
            newArray = [item, ...formattedDoctors]
            arrayForOrder = [item]
          }
          newArray.splice(index + 1, 1)
        }
      })
    })
    arrayForOrder.sort((a, b) => a.name > b.name ? 1 : -1); // Ordernar os coordenadores por ordem alfabetica
    newArray.forEach((item, index) => {
      if (index < arrayForOrder.length) {
        formattedDoctors[index] = arrayForOrder[index]
        return;
      }
      formattedDoctors[index] = item
    })
  }